<template>
  <div class="table">
    <div class="header">
      <div class="header_1">{{title}}</div>
      <div class="header_2">
        <div class="header_2_1">同期对比</div>
        <div class="header_2_2">
          <div class="label">本年</div>
          <div class="label">去年</div>
          <div class="label">前年</div>
        </div>
      </div>
      <div class="header_3">
        <div class="header_3_1">未来三个月预测</div>
        <div class="header_3_2">
          <div class="label">去年</div>
          <div class="label">前年</div>
        </div>
      </div>
    </div> 
    <div class="body">
      <div class="body_1">合计</div>
      <div class="body_2">
        <div class="label">34</div>
        <div class="label">432</div>
        <div class="label">132</div>
      </div>
      <div class="body_3">
        <div class="label">10</div>
        <div class="label">0</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    }
  }
  
}
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  font-size: 12px;
  text-align: center;
  .header {
    width: 100%;
    background-color: #fff;
    height: 58px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
    &_1 {
      width: 80px;
      line-height: 58px;
    }
    &_2, &_3 {
      width: 129px;
      line-height: 29px;
      &_2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .label {
          flex: 1;
        }
      }
    }
  }
  .body {
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
    height: 30px;
    line-height: 30px;
    background-color: #fff;
    &_1 {
      width: 80px;
    }
    &_2, &_3 {
      width: 129px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .label {
        flex: 1;
      }
    }
  }
}
</style>